<template>
  <div
    style="
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 200;
    "
  >
    <v-progress-circular
      style="text-align: center"
      :size="70"
      :width="7"
      color="purple"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "loadingCircle",
};
</script>
